import React from 'react';
import useBanners from '../../../../hooks/banners';
import Banner from './Banner';

const Banners = () => {
  const { banner, onPress, visible } = useBanners();

  if (!banner) return null;

  return (
    <Banner
      title={banner?.title}
      description={banner?.description}
      onPress={onPress}
      visible={visible}
      variant={banner?.variant}
    />
  )
};

export default Banners;