import theme from "../theme"

/*
  Return values are structured with future additional fields in mind.
  eg. return { colors: ... , icon: .... , titleFontFamily: ... }
*/

const getVariantStyles = (variant) => {
  switch (variant) {
    case 'notify':
      return { colors: theme.colors.info }
    case 'warning':
      return { colors: theme.colors.warning }
    case 'error':
      return { colors: theme.colors.error }
    case 'success':
      return { colors: theme.colors.success }
    default:
      return null;
  }
}

export default getVariantStyles;
