import React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { Snackbar, Portal, Text } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux';
import { clear } from '../reducers/notificationReducer';
import theme from '../theme/index';

const Notification = () => {
  const dispatch = useDispatch();
  const notification = useSelector(({ notification }) => notification);
  const width = useWindowDimensions().width

  const onDismissSnackBar = () => {
    dispatch(clear());
  };

  return (
    <Portal>
      <Snackbar
        visible={notification}
        onDismiss={onDismissSnackBar}
        duration={2000}
        style={{
          backgroundColor: notification?.color,
          width: '70%',
          height: '5%',
          alignSelf: 'center',
          marginBottom: width > 1100 ? 15 : 75
        }}
        theme={{ colors: { surface: 'white', accent: 'red' } }}
      >
        <Text style={styles.text}>{notification?.message}</Text>
      </Snackbar>
    </Portal>
  );
};

const styles = StyleSheet.create({
  text: {
    color: theme.colors.mainBackground
  }
})

export default Notification;
