import React from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { Badge, Button } from 'react-native-paper';
import theme from '../theme/index';

const NavTab = ({ color, title, icon, showBadge }) => {

  return (
    <View style={styles.container}>
      <Button icon={showBadge ? () => <IconWithBadge icon={icon} /> : () => icon} labelStyle={styles({ color }).title}>{title}</Button>
    </View>
  )
}

export const IconWithBadge = ({ icon, right }) => {
  const productCount = useSelector(state => state.currentOrder.cartProducts.reduce((acc, curr) => curr.amount + acc, 0))

  return (
    <View>
      <Badge size={15} style={{ position: 'absolute', right, top: 0 }} visible={productCount !== 0}>{productCount}</Badge>
      {icon}
    </View>
  )
}

const styles = ({ color }) => StyleSheet.create({
  container: {
    backgroundColor: theme.colors.navigation,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontFamily: theme.fonts.heading,
    fontSize: 24,
    color
  }
})

export default NavTab;