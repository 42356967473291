/* eslint-disable no-undef */
import React from 'react';
import { useLinkTo } from '@react-navigation/native';
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { IconButton, Title } from 'react-native-paper';
import { Tietosuojaseloste, Toimitusehdot } from '../../assets/terms';
import Footer from '../../components/Footer';
import theme from '../../theme/index';

const Terms = () => {

  return (
    <View style={styles.container}>
      <Header />
      <View style={styles.termsContainer}>
        <Title style={styles.title}>1. Toimitusehdot</Title>
        <Toimitusehdot />
        <Title style={styles.title}>2. Tietosuojaseloste</Title>
        <Tietosuojaseloste />
      </View>
      <Footer />
    </View>
  )
}

const Header = () => {
  const linkTo = useLinkTo()

  return (
    <View style={styles.headerContainer}>
      <View style={styles.logoContainer}>
        <TouchableOpacity
          onPress={() => linkTo('/')}
          style={styles.logoContainer}
          activeOpacity={1}
        >
          <Image
            source={require('../../assets/bummaLogoPetrol.png')}
            style={styles.logoContainer}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.right}>
        <IconButton
          icon="facebook"
          size={24}
          color={theme.colors.primary}
          onPress={() => window.open('https://www.facebook.com/bummapizzabar')}
        />
        <IconButton
          icon="instagram"
          size={24}
          color={theme.colors.primary}
          onPress={() => window.open('https://www.instagram.com/bummapizzabar/')}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.mainBackground
  },
  termsContainer: {
    alignSelf: 'center',
    backgroundColor: theme.colors.mainBackground,
    maxWidth: 1000,
    marginHorizontal: '5%',
    marginVertical: '2%'
  },
  headerContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 100,
    backgroundColor: theme.colors.navigation,
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  logoContainer: {
    height: 90,
    width: 90,
  },
  right: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    marginVertical: 10,
    fontWeight: theme.fontWeights.bold
  }
})

export default Terms;
