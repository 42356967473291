import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import { getAll as getAllProducts } from '../../../services/productsService';
import ProductListSection from './ProductListSection';

const ProductList = () => {
  const filter = useSelector(({ filter }) => filter)
  const { productCategories } = useSelector(({ restaurant }) => restaurant);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    const getProductsAndCategories = async () => {
      const products = await getAllProducts()
      setProductList(products)
    }
    getProductsAndCategories()
  }, [])

  if (!productCategories || !productList) return <ActivityIndicator />

  return (
    <View style={styles.container}>
      {productCategories.filter((category) =>
        filter !== null
          ? category.id === filter
          : true)
        .map((category, index) =>
          <ProductListSection
            isFirst={index === 0}
            key={category.id}
            productList={productList}
            category={category} />
        )
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'stretch'
  },
})


export default ProductList;