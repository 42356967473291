import axios from 'axios';
import Constants from 'expo-constants';

const baseUrl = Constants.manifest.extra.uri;
const restaurantId = Constants.manifest.extra.restaurantId;

export const getAll = async () => {
  const response = await axios.get(`${baseUrl}/api/deliveries/visible?restaurant=${restaurantId}`)
  return response.data
};
