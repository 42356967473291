import axios from "axios";
import Constants from "expo-constants";

const baseUrl = Constants.manifest.extra.uri;
const restaurant = Constants.manifest.extra.restaurantId;

export const sendOrder = async (order) => {
  const response = await axios.post(`${baseUrl}/api/orders`, {
    ...order,
    restaurant,
  });

  return response.data;
};

export const getOrder = async (orderId, key) => {
  const response = await axios.get(`${baseUrl}/api/orders/${orderId}?key=${key}`);
  return response.data;
}

