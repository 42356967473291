import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Modal, Portal, Paragraph, Subheading, Title, Button } from 'react-native-paper';
import theme from '../../../theme/index';

const ProductInfoModal = ({ visible, onDismiss, product }) => {

  const ingredients = () => {
    if (product.ingredients.length === 0) {
      return 'Kysy lisää ainesosista ravintolalta'
    }
    if (product.ingredients.length === 1) {
      return product.ingredients[0].name
    }
    return product.ingredients.reduce((acc, current) => acc.name + ', ' + current.name)
  }

  const allergens = product.allergens.length === 0
    ? 'Kysy lisää allergeeneista ravintolalta'
    : product.allergens.reduce((acc, current) => acc + ', ' + current)

  return (
    <Portal>
      <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.header}>
            <Title style={styles.title} >{product.name}</Title>
          </View>
          <View style={styles.content}>
            <View style={styles.subcategoryContainer}>
              <Subheading style={styles.subcategoryTitle}>Ainesosat</Subheading>
              <Paragraph style={styles.paragraph}>{ingredients()}</Paragraph>
            </View>
            <View style={styles.subcategoryContainer}>
              <Subheading style={styles.subcategoryTitle} >Allergeenit</Subheading>
              <Paragraph style={styles.paragraph}>{allergens}</Paragraph>
            </View>
            <View style={styles.subcategoryContainer}>
              <Subheading style={styles.subcategoryTitle} >Tuotekuvaus</Subheading>
              <Paragraph style={styles.paragraph}>{product.description ? product.description : 'Tuotteella ei ole kuvausta'}</Paragraph>
            </View>
          </View>
        </ScrollView>
        <View>
          <Button
            style={styles.closeButton}
            onPress={onDismiss}
          >
            Sulje</Button>
        </View>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    width: '90%',
    height: '70%',
    maxWidth: 500,
    maxHeight: 500,
    backgroundColor: theme.colors.mainBackground,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 15
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontFamily: theme.fonts.heading,
    color: theme.colors.primary,
    fontSize: 24
  },
  content: {
    justifyContent: 'flex-start'
  },
  subcategoryContainer: {
    marginTop: 10,
    padding: 5
  },
  subcategoryTitle: {
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary,
    fontWeight: theme.fontWeights.bold,
    fontSize: 18,
    letterSpacing: 1
  },
  paragraph: {
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary
  },
  closeButton: {
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default ProductInfoModal;