import { sendOrder } from '../services/orderService';
import theme from '../theme/index';
import { toggleDialog } from './dialogReducer';
import { notify } from './notificationReducer';

const initialState = {
  cartProducts: [],
  restaurant: null,
  subTotal: 0,
  delivery: { name: null, priceInCents: 0 },
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_SHOPPING_CART': {
      return {
        ...state,
        cartProducts: action.data.cart,
        subTotal: action.data.subTotal,
      };
    }
    case 'ADD_PRODUCT_TO_ORDER': {
      const exists = state.cartProducts.find(
        (cartProduct) =>
          JSON.stringify(cartProduct.product) === JSON.stringify(action.data.cartProduct)
      );

      if (exists) {
        const productToUpdate = exists;

        const updatedProduct = {
          product: productToUpdate.product,
          amount: productToUpdate.amount + 1,
        };

        const updatedProductList = state.cartProducts.map((item) =>
          item.product === updatedProduct.product ? updatedProduct : item
        );

        localStorage.setItem('cart', JSON.stringify(updatedProductList));
        localStorage.setItem('timestamp', new Date());

        return {
          ...state,
          cartProducts: updatedProductList,
          restaurant: action.data.cartProduct.product.restaurant,
        };
      }
      const newProducts = state.cartProducts.concat({
        product: action.data.cartProduct,
        amount: 1,
      });

      localStorage.setItem('cart', JSON.stringify(newProducts));
      localStorage.setItem('timestamp', new Date());

      return { ...state, cartProducts: newProducts };
    }
    case 'REMOVE_PRODUCT_FROM_ORDER': {
      if (action.data.product.amount > 1) {
        const cartProductToUpdate = state.cartProducts.find(
          (cartProduct) => JSON.stringify(cartProduct) === JSON.stringify(action.data.product)
        );

        const updatedCartProduct = {
          ...cartProductToUpdate,
          amount: cartProductToUpdate.amount - 1,
        };

        const updatedProductList = state.cartProducts.map((cartProduct) => {
          return JSON.stringify(cartProduct) === JSON.stringify(cartProductToUpdate)
            ? updatedCartProduct
            : cartProduct;
        });

        localStorage.setItem('cart', JSON.stringify(updatedProductList));

        return { ...state, cartProducts: updatedProductList };
      } else {
        const updatedProductList = state.cartProducts.filter(
          (product) => product !== action.data.product
        );

        localStorage.setItem('cart', JSON.stringify(updatedProductList));

        return { ...state, cartProducts: updatedProductList };
      }
    }
    case 'REMOVE_ALL_INSTANCES_OF_PRODUCT_FROM_ORDER': {
      const updatedProductList = state.cartProducts.filter(
        (product) => JSON.stringify(product) !== JSON.stringify(action.data)
      );
      localStorage.setItem('cart', JSON.stringify(updatedProductList));
      return { ...state, cartProducts: updatedProductList };
    }
    case 'INCREASE_SUBTOTAL':
      return {
        ...state,
        subTotal: action.data.amount + state.subTotal,
      };
    case 'DECREASE_SUBTOTAL':
      return {
        ...state,
        subTotal: state.subTotal - action.data.amount,
      };
    case 'SET_DELIVERY':
      return { ...state, delivery: action.data.delivery };
    case 'SET_ORDER_DETAILS':
      return { ...state, ...action.data.details };
    default:
      return state;
  }
};

// Action creators:

export const initializeShoppingCart = () => {
  const timeNow = new Date();
  const timestamp = localStorage.getItem('timestamp');
  const differenceInMilliseconds = timeNow - Date.parse(timestamp);
  if (differenceInMilliseconds > 1800000) {
    localStorage.clear();
  }
  localStorage.setItem('timestamp', new Date());
  let storageCart = JSON.parse(localStorage.getItem('cart'));
  const storageSubtotal = Number(localStorage.getItem('subtotal'));

  return (dispatch) => {
    dispatch({
      type: 'INITIALIZE_SHOPPING_CART',
      data: {
        cart: storageCart ? storageCart : [],
        subTotal: storageSubtotal,
      },
    });
  };
};

export const addProductToOrder = (cartProduct) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_PRODUCT_TO_ORDER',
      data: {
        cartProduct,
      },
    });

    dispatch(increaseSubtotal(cartProduct.product.priceInCents));
  };
};

export const removeProductFromOrder = (product) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_PRODUCT_FROM_ORDER',
      data: {
        product,
      },
    });

    dispatch(decreaseSubtotal(product.product.subtotal));
  };
};

export const removeAllInstancesOfProductFromOrder = (product) => {
  return (dispatch) => {
    dispatch({
      type: 'REMOVE_ALL_INSTANCES_OF_PRODUCT_FROM_ORDER',
      data: product,
    });

    dispatch(decreaseSubtotal(product.amount * product.product.subtotal));
  };
};

export const removeSelectedProductsFromOrder = (unavailableProducts) => {
  return (dispatch, getState) => {
    const cartProducts = getState().currentOrder.cartProducts;
    cartProducts.map((cartProduct) => {
      unavailableProducts.map((unavailableProduct) => {
        if (cartProduct.product.product.id === unavailableProduct.id) {
          dispatch(removeAllInstancesOfProductFromOrder(cartProduct));
          dispatch(notify({ message: 'Tuotteet poistettu', color: theme.colors.error.main }));
        }
      });
    });
  };
};

export const removeProductsWithUnavailableChoicesFromOrder = (unavailableChoices) => {
  return (dispatch, getState) => {
    const cartProducts = getState().currentOrder.cartProducts;
    cartProducts.map((cartProduct) => {
      unavailableChoices.map((choice) => {
        if (
          cartProduct.product.addedIngredients.find((ingredient) => ingredient.id === choice.id)
        ) {
          dispatch(removeAllInstancesOfProductFromOrder(cartProduct));
          dispatch(notify({ message: 'Tuotteet poistettu', color: theme.colors.error.main }));
        }
      });
    });
  };
};

export const increaseSubtotal = (amount) => {
  const newStorageSubtotal = Number(localStorage.getItem('subtotal')) + amount;
  localStorage.setItem('subtotal', newStorageSubtotal);

  return (dispatch) => {
    dispatch({
      type: 'INCREASE_SUBTOTAL',
      data: {
        amount,
      },
    });
  };
};

export const decreaseSubtotal = (amount) => {
  const newStorageSubtotal = Number(localStorage.getItem('subtotal')) - amount;
  localStorage.setItem('subtotal', newStorageSubtotal);

  return (dispatch) => {
    dispatch({
      type: 'DECREASE_SUBTOTAL',
      data: {
        amount,
      },
    });
  };
};

export const setDelivery = (delivery) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_DELIVERY',
      data: {
        delivery,
      },
    });
  };
};

export const submitOrder = (order, delivery, details, openPaymentPage) => {
  return async (dispatch) => {
    try {
      const response = await sendOrder({
        ...details,
        cart: order.cartProducts.map((cartProduct) => ({
          amount: cartProduct.amount,
          product: cartProduct.product.product.id,
          addedIngredients: cartProduct.product.addedIngredients.map((ingredient) => ingredient.id),
          additionalDetails: cartProduct.product.additionalDetails,
        })),
        delivery: delivery.id,
      });
      openPaymentPage({ ...details, orderId: response.id });
    } catch (err) {
      if (err.response.data.error.includes('Path `delivery` is required')) {
        dispatch(
          notify({
            message: 'Ei toimitustapaa valittuna',
            color: theme.colors.error.main,
          })
        );
      }
      
      const unavailableProducts = err.response.data?.error?.unavailableProducts;
      const unavailableChoices = err.response.data?.error?.unavailableChoices;
      
      if (unavailableProducts?.length > 0) {
        dispatch(
          toggleDialog({
            visible: true,
            title: 'Korissa ei saatavilla olevia tuotteita',
            paragraph: '"Vahvista" poistaa ei saatavilla olevat tuotteet',
            onConfirm: () => dispatch(removeSelectedProductsFromOrder(unavailableProducts)),
          })
        );
      }

      if (unavailableChoices?.length > 0) {
        dispatch(
          toggleDialog({
            visible: true,
            title: 'Korissa ei saatavilla olevia ainesosia',
            paragraph: '"Vahvista" poistaa ei saatavilla olevia ainesosia sisältävät tuotteet',
            onConfirm: () =>
              dispatch(removeProductsWithUnavailableChoicesFromOrder(unavailableChoices)),
          })
        );
      }

      if (unavailableProducts?.length === 0 && unavailableChoices?.length === 0) {
        dispatch(
          notify({
            message: 'Jotain meni pieleen, yritä hetken päästä uudelleen',
            color: theme.colors.error.main,
          })
        );
      }
    }
  };
};

export default orderReducer;
