import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Dialog, Portal, Paragraph } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { clearDialog } from '../reducers/dialogReducer';

const ConfirmationDialog = () => {
  const dispatch = useDispatch()
  const dialog = useSelector(state => state.dialog);

  const confirm = () => {
    dispatch(clearDialog())
    dialog?.onConfirm();
  };

  const cancel = () => {
    if (dialog?.onCancel) dialog?.onCancel();
    dispatch(clearDialog())
  };

  return (
    <View>
      <Portal>
        <Dialog
          visible={dialog?.visible}
          onDismiss={cancel}
          style={styles.container}
        >
          <Dialog.Title>{dialog?.title}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{dialog?.paragraph}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={cancel}>Peruuta</Button>
            <Button onPress={confirm}>Vahvista</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '90%',
    maxWidth: 500,
  }
})

export default ConfirmationDialog; 