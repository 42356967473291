import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native';
import { Paragraph, Title, Button } from 'react-native-paper';
import ScreenContainer from '../../layouts/ScreenContainer/index';
import theme from '../../theme/index';
import { initPayment } from '../../services/paymentService';
import PaymentOptionsList from './PaymentOptionsList';
import { useLinkTo } from '@react-navigation/native';

const OrderDetails = ({ route }) => {
  const [methods, setMethods] = useState(null);
  const linkTo = useLinkTo()

  useEffect(() => {
    const init = async (orderId) => await initPayment({ orderId })
    init(route.params.orderId).then(res => setMethods(res)).catch(err => console.log('err', err))
  }, []);

  return (
    <ScreenContainer>
      <View style={styles.container}>
        <Title style={styles.title}>Valitse maksutapa</Title>
        <Paragraph style={{ marginVertical: 10, textAlign: 'center' }}>Maksun suoritettuasi odotathan uudelleenohjausta takaisin verkkokauppaan</Paragraph>
        <Paragraph style={{ marginVertical: 10, textAlign: 'center' }}>Valitsemalla maksutavan hyväksyt <Paragraph onPress={() => window.open('https://www.checkout.fi/ehdot-ja-sopimukset/maksuehdot')} style={{ color: theme.colors.primary }} >maksupalveluehdot</Paragraph></Paragraph>
        <PaymentOptionsList methods={methods} />
        <Button mode='contained' onPress={() => linkTo('/cart/details')} style={{ marginTop: 20 }} >TAKAISIN</Button>
      </View>
    </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 20,
    backgroundColor: theme.colors.mainBackground
  },
  title: {
    fontFamily: theme.fonts.primary,
    fontSize: 26
  }
})

export default OrderDetails;
