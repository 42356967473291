import axios from "axios";
import Constants from "expo-constants";

const baseUrl = Constants.manifest.extra.uri;
const restaurantId = Constants.manifest.extra.restaurantId;

export const getOne = async () => {
  const response = await axios.get(`${baseUrl}/api/restaurants/${restaurantId}/visible`)

  return response.data
};
