/* eslint-disable no-undef */
import React from 'react';
import { View, Image } from 'react-native';
import { Banner as PaperBanner, Paragraph, Subheading } from 'react-native-paper';
import theme from '../../../../theme';
import getVariantStyles from '../../../../utils/banners';

const Banner = ({ title, description, visible, onPress, variant }) => {
  const style = getVariantStyles(variant);

  const styles = {
    bannerRoot: {
      boxShadow: '5px 5px 20px',
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      zIndex: 10,
    },
    buttonRoot: {
      marginBottom: 10,
      marginRight: 20,
    },
    buttonContent: {
      width: '5rem',
      backgroundColor: style?.colors.main
    },
    title: {
      color: style.colors.main,
      fontWeight: theme.fontWeights.bold
    }
  };

  return (
    <PaperBanner
      visible={visible}
      actions={[
        {
          label: 'OK',
          onPress,
          mode: 'contained',
          style: styles.buttonRoot,
          contentStyle: styles.buttonContent,
        }
      ]}
      style={styles.bannerRoot}
      icon={({ size }) => (
        <Image
          source={require('../../../../assets/bummaLogoWhite.png')}
          style={{ width: size, height: size, tintColor: style?.colors.main }}
        />
      )}
    >
      <View>
        <Subheading style={styles.title}>{title}</Subheading>
        <Paragraph>{description}</Paragraph>
      </View>
    </PaperBanner>
  );
}


export default Banner;
