import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useBanners = () => {
  const banners = useSelector(({ restaurant }) => restaurant?.banners) || [];
  const [index, setIndex] = useState(0);
  const [banner, setBanner] = useState(banners[index]);
  const [visible, setVisible] = useState(true);
  const hasNext = index < banners.length - 1;

  const onPress = () => {
    if (!hasNext) setVisible(false);
    getNext();
  }

  const getNext = () => {
    if (index < banners.length - 1) setIndex(index + 1);
  };

  useEffect(() => {
    setBanner(banners[index]);
  }, [index]);

  return { banner, onPress, visible }
}

export default useBanners;
