import React, { useEffect } from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../reducers/filterReducer';
import { useNavigation, useRoute } from '@react-navigation/native';
import theme from '../../theme/index';

const CategoryScroll = () => {
  const { productCategories } = useSelector(({ restaurant }) => restaurant);

  return (
    <View style={styles.container}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
      >
        {productCategories.map(category => <CategoryButton key={category.id} category={category} />)}
      </ScrollView>
    </View>
  )
}

const CategoryButton = ({ category }) => {
  const dispatch = useDispatch()
  const filter = useSelector(({ filter }) => filter)
  const navigation = useNavigation();
  const { params } = useRoute();

  useEffect(() => {
    if (params?.category === category.id) {
      dispatch(setFilter(category.id));
    }
  }, []);

  const useFilter = () => {
    navigation.setParams(params?.category === category.id
      ? { category: undefined }
      : { category: category.id }
    );
    dispatch(setFilter(category.id))
  }

  const isActive = () => {
    return category.id === filter
  }

  return (
    <View style={styles.container}>
      <Button
        mode='text'
        style={isActive() ? styles.activeButton : styles.inactiveButton}
        labelStyle={isActive() ? styles.activeButton : styles.inactiveButton}
        onPress={useFilter}>
        {category.name}
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    backgroundColor: theme.colors.navigation,
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  activeButton: {
    fontSize: theme.fontSizes.categoryScroll,
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary,
  },
  inactiveButton: {
    fontSize: theme.fontSizes.categoryScroll,
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary,
    opacity: 0.7
  }
})

export default CategoryScroll;