import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import NavBar from './NavBar';
import OrderTracking from '../screens/OrderTracking/index';
import Terms from '../screens/Terms/index';

const Stack = createStackNavigator();

const RootStack = () => (
  <Stack.Navigator
    screenOptions={{
      headerShown: false
    }}
  >
    <Stack.Screen
      name='NavBar'
      component={NavBar}
    />
    <Stack.Screen
      name='Seuranta'
      component={OrderTracking}
    />
    <Stack.Screen
      name='Ehdot'
      component={Terms}
    />
  </Stack.Navigator>
)

export default RootStack;