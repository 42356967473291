/* eslint-disable no-undef */
import React from 'react';
import { StyleSheet, View, Image, ImageBackground } from 'react-native';
import Banners from './Banners';
import OpenClosedChip from './OpenClosedChip';

const RestaurantHeader = () => {
  return (
    <View>
      <Banners />
      <ImageBackground
        style={styles.background}
        source={require('../../../assets/header.png')}
      >
        <Image 
          source={require('../../../assets/bummaLogoWhite.png')}
          style={styles.logo}
        />
        <OpenClosedChip />
      </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  background: {
    height: 425,
    marginTop: -25,
    alignItems: 'center',
    justifyContent: 'center',

    /*
      This padding equals the amount that the restaurant body (categoryScroll + menu) overlaps
      the header. Having the padding "cancel out" the overlap makes it easier to work with
      flexbox inside the header. ex. setting the justify-content above to flex-end would put the logo
      right above the categoryScroll instead of under it
    */
    paddingBottom: 100
  },
  logo: {
    height: 150,
    width: 150,
    marginBottom: 10
  }
})

export default RestaurantHeader;