import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Paragraph, Title } from 'react-native-paper';
import theme from '../../../../theme/index';
import Ingredient from './Ingredient';

const ChoiceGroup = ({ name, choices, maxChoices, ...props }) => {
  const [currentChoices, setCurrentChoices] = useState([]);
  const choicesRemaining = maxChoices - currentChoices.length

  const infoText = () => {
    if (choicesRemaining === 1) return `${choicesRemaining} valinta jäljellä`
    if (choicesRemaining === 0) return 'Ei enempää valintoja'

    return `${choicesRemaining} valintaa jäljellä`
  }

  return (
    <View style={styles.container} >
      <View style={styles.textContainer}>
        <Title style={styles.title}>{name}</Title>
        {maxChoices && <Paragraph style={styles.infoText}>{infoText()}</Paragraph>}
      </View>
      {choices.map(choice => <Ingredient
        key={choice.name}
        ingredient={choice}
        maxChoices={maxChoices}
        currentChoices={currentChoices}
        setCurrentChoices={setCurrentChoices}
        {...props} />)}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 5
  },  
  infoText: {
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.cart.additionalDetails,
    color: theme.colors.primary,
    backgroundColor: theme.colors.navigation,
    paddingHorizontal: 8,
    paddingVertical: 1,
    borderRadius: 5,
    width: 'max-content'
  },
  title: {
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary,
    fontWeight: theme.fontWeights.bold,
    fontSize: 20
  },
  textContainer: {
    marginVertical: 10
  }
})

export default ChoiceGroup;