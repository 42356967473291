import { applyMiddleware, createStore, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";

import dialogReducer from './reducers/dialogReducer';
import filterReducer from "./reducers/filterReducer";
import notificationReducer from "./reducers/notificationReducer";
import orderReducer from "./reducers/orderReducer";
import restaurantReducer from "./reducers/restaurantReducer";

const reducer = combineReducers({
  restaurant: restaurantReducer,
  currentOrder: orderReducer,
  notification: notificationReducer,
  filter: filterReducer,
  dialog: dialogReducer,
})

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
);

export default store;
