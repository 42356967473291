import React from 'react';

import { StyleSheet, View } from 'react-native';
import { IconButton, Paragraph } from 'react-native-paper';
import theme from '../theme/index';

const Footer = () => {
  return (
    <View style={styles.container}>
      <View style={styles.bumma}>
        <Paragraph style={styles.subheading} onPress={() => window.open('https://www.bumma.fi')}>Bumma</Paragraph>
        <Paragraph style={styles.paragraph}>Sibeliuksenkatu 19</Paragraph>
        <Paragraph style={styles.paragraph}>04400 Järvenpää</Paragraph>
        <Paragraph style={styles.paragraph}>+358 50 5432591</Paragraph>
        <Paragraph style={styles.paragraph}>myynti@bumma.fi</Paragraph>
        <View style={styles.socials}>
          <IconButton
            icon="facebook"
            size={24}
            color={theme.colors.mainBackground}
            onPress={() => window.open('https://www.facebook.com/bummapizzabar')}
          />
          <IconButton
            icon="instagram"
            size={24}
            color={theme.colors.mainBackground}
            onPress={() => window.open('https://www.instagram.com/bummapizzabar/')}
          />
        </View>
      </View>
      <View style={styles.deli}>
        <Paragraph style={styles.subheading}>Bummadeli</Paragraph>
        <Paragraph style={styles.paragraph}>Sibeliuksenkatu 19</Paragraph>
        <Paragraph style={styles.paragraph}>04400, Järvenpää</Paragraph>
        <Paragraph style={styles.paragraph}>bummadeli@gmail.com</Paragraph>
        <View style={styles.terms}>
          <Paragraph
            style={styles.paragraph}
            onPress={() => window.open('https://bummadeli.fi/terms')}
          >Käyttöehdot</Paragraph>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.colors.primary,
    paddingBottom: 15,
  },
  terms: {
    marginTop: 15
  },
  socials: {
    flexDirection: 'row'
  },
  bumma: {
    flex: 1,
    width: '100%',
    minWidth: 300,
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    marginTop: 15
  },
  deli: {
    flex: 1,
    minWidth: 300,
    width: '100%',
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    marginTop: 15
  },
  subheading: {
    color: theme.colors.mainBackground,
    fontFamily: theme.fonts.footer,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  paragraph: {
    color: theme.colors.mainBackground,
    fontFamily: theme.fonts.footer,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: 11
  }
})

export default Footer;