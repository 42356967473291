import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useSelector } from 'react-redux';
import theme from '../../../theme/index';

export const Footer = () => {
  const restaurant = useSelector(({ restaurant }) => restaurant);
  const productSubTotal = useSelector(state => state.currentOrder.subTotal)
  const delivery = useSelector(state => state.currentOrder.delivery)

  if (!restaurant) {
    return <ActivityIndicator />
  }

  const subTotal = productSubTotal + delivery.priceInCents

  const formatSubTotal = () => {
    return `${(subTotal / 100).toFixed(2)}€`
  }

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Yhteensä: </Text>
      <Text style={styles.text}>{formatSubTotal()}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  text: {
    fontFamily: theme.fonts.primary,
    fontWeight: theme.fontWeights.bold,
    fontSize: theme.fontSizes.subheading,
    color: theme.colors.primary,
    textTransform: 'uppercase'
  }
})
