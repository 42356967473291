import React, { useState } from 'react'
import { useLinkTo } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { Button, Title } from 'react-native-paper';
import ScreenContainer from '../../layouts/ScreenContainer/index'
import DetailsForm from './DetailsForm';
import theme from '../../theme/index';
import TermsModal from './TermsModal';

const OrderDetails = ({ navigation }) => {
  const linkTo = useLinkTo()
  const [checked, setChecked] = useState(false)
  const [visible, setVisible] = useState(false)

  return (
    <ScreenContainer>
      <View style={styles.container}>
        <View>
          <Title style={styles.title}>Tilaajan tiedot</Title>
        </View>
        <DetailsForm setVisible={setVisible} checked={checked} setChecked={setChecked} navigation={navigation} />
        <Button mode='contained' onPress={() => linkTo('/cart/preview')} >TAKAISIN</Button>
        <TermsModal visible={visible} setVisible={setVisible} setChecked={setChecked} />
      </View>
    </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    backgroundColor: theme.colors.mainBackground
  },
  title: {
    fontFamily: theme.fonts.primary,
    fontSize: 26
  }
})

export default OrderDetails;
