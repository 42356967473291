import React from 'react';

import { View, StyleSheet } from 'react-native';
import { Title, Button } from "react-native-paper"
import { useLinkTo } from '@react-navigation/native';
import theme from '../../theme/index';

const PaymentCancel = () => {
  const linkTo = useLinkTo()

  return (
    <View style={styles.container}>
      <Title>Maksu peruutettu</Title>
      <Button
        onPress={() => linkTo('/')}
        mode='contained'
      >
        Etusivulle
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.mainBackground
  }
})

export default PaymentCancel;
