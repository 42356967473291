const reducer = (state = null, action) => {
  switch (action.type) {
    case 'TOGGLE_DIALOG':
      return action.data;
    case 'CLEAR_DIALOG':
      return null;
    default:
      return state;
  }
};

export const toggleDialog = (dialogProps) => async (dispatch) => {
  dispatch({
    type: 'CLEAR_DIALOG',
  });
  dispatch({
    type: 'TOGGLE_DIALOG',
    data: dialogProps,
  });
};

export const clearDialog = () => async (dispatch) => {
  dispatch({
    type: 'CLEAR_DIALOG',
  });
};

export default reducer;
