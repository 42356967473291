import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { IconButton, Paragraph, Subheading, TouchableRipple } from 'react-native-paper';
import RemoveProductConfirmationModal from '../RemoveProductConfirmationModal';
import theme from '../../../theme/index';

export const ListItem = ({ cartProduct }) => {
  const [visible, setVisible] = useState(false);
  const [numberOfLines, setNumberOfLines] = useState(1)
  const product = cartProduct.product.product
  const addedIngredients = cartProduct.product.addedIngredients
  const additionalDetails = cartProduct.product.additionalDetails
  const subtotal = (cartProduct.product.subtotal / 100).toFixed(2)

  const onDismiss = () => {
    setVisible(false);
  }

  return (
    <TouchableRipple
      onPress={() => setNumberOfLines(numberOfLines === 1 ? 3 : 1)}
      onLongPress={() => setVisible(true)}
    >
      <View style={styles.container}>
        <View style={styles.upper}>
          <Subheading style={styles.title}>{cartProduct.amount} × {product.name}</Subheading>
          <Subheading style={styles.subtotal}>{subtotal}€</Subheading>
        </View>
        <View style={styles.lower}>
          <View style={styles.left}>
            {addedIngredients.length > 0 &&
              <Paragraph
                numberOfLines={numberOfLines}
                style={styles.addedIngredients}
              >
                + {addedIngredients.map((ingredient, index) => `${ingredient.name}${(index + 1) === addedIngredients.length ? '' : ', '}`)}
              </Paragraph>
            }
            {additionalDetails.length > 0 &&
              <Paragraph
                numberOfLines={numberOfLines}
                style={styles.additionalDetails}
              >
                Lisätietoja: {additionalDetails}
              </Paragraph>
            }
          </View>
          <View style={styles.right}>
            <IconButton
              icon="minus"
              color={theme.colors.error.main}
              size={20}
              onPress={() => setVisible(true)}
            />
          </View>
        </View>
        <RemoveProductConfirmationModal visible={visible} onDismiss={onDismiss} cartProduct={cartProduct} />
      </View>
    </TouchableRipple>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 10
  },
  upper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lower: {
    flexDirection: 'row',
  },
  left: {
    width: '85%'
  },
  right: {
    flexGrow: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  title: {
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.cart.title,
    fontWeight: theme.fontWeights.bold,
    color: theme.colors.primary
  },
  subtotal: {
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.cart.title,
    color: theme.colors.primary
  },
  addedIngredients: {
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.cart.ingredients,
    color: theme.colors.primary,
    textTransform: 'lowercase'
  },
  additionalDetails: {
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.cart.additionalDetails,
    color: theme.colors.primary
  }
})