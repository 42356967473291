import axios from "axios";
import Constants from "expo-constants";

const baseUrl = Constants.manifest.extra.uri
const restaurant = Constants.manifest.extra.restaurantId


export const getAll = async () => {
  const response = await axios.get(`${baseUrl}/api/products?restaurant=${restaurant}`)

  return response.data
};