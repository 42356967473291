import React from 'react';
import { useSelector } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import { Divider } from 'react-native-paper';
import { ListItem } from './ListItem';
import { Footer } from './Footer';

export const ProductList = () => {
  const cartProducts = useSelector(state => state.currentOrder.cartProducts);

  return (
    <View>
      <Divider style={styles.divider} />
      <View style={styles.container}>
        {cartProducts.map((cartProduct, index) => <ListItem key={index} cartProduct={cartProduct} />)}
      </View>
      <Divider style={styles.divider} />
      <Footer products={cartProducts} />
      <Divider style={styles.divider} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 300
  },
  divider: {
    marginVertical: 10
  }
})