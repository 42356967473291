import React from 'react';
import { Button, Modal, Portal, Title } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { removeProductFromOrder } from '../../reducers/orderReducer';
import { notify } from '../../reducers/notificationReducer';
import theme from '../../theme/index';

const RemoveConfirmationModal = ({ cartProduct, visible, onDismiss }) => {
  const dispatch = useDispatch();

  const removeItemFromOrder = () => {
    dispatch(removeProductFromOrder(cartProduct))
    dispatch(notify({ message: 'Tuote poistettu', color: theme.colors.error.main }))
    onDismiss()
  }

  return (
    <Portal>
      <Modal
        visible={visible}
        contentContainerStyle={styles.container}
        onDismiss={onDismiss}
      >
        <Title numberOfLines={2} style={styles.text}>Poistetaanko tuote?</Title>
        <View style={styles.buttonContainer}>
          <Button mode='outlined' onPress={onDismiss} style={styles.cancelButton} labelStyle={styles.cancelButton}>Peruuta</Button>
          <Button mode='contained' onPress={removeItemFromOrder} style={styles.confirmButton} labelStyle={styles.confirmButton}>Poista</Button>
        </View>
      </Modal>
    </Portal>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    height: 150,
    width: 250,
    marginHorizontal: 10,
    borderRadius: 10,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignSelf: 'center'
  },
  text: {
    fontSize: 18,
    fontFamily: theme.fonts.primary,
    maxWidth: 200
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly'
  },
  cancelButton: {
    
  },
  confirmButton: {
    backgroundColor: '#d11a2a',
    color: 'white'
  }
})

export default RemoveConfirmationModal;