const filterReducer = (state = null, action) => {
  switch (action.type) {
    case 'SET_FILTER':
      if (state === action.data.categoryId) return null
      return action.data.categoryId
    default:
      return state
  }
};

export const setFilter = (categoryId) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_FILTER',
      data: {
        categoryId
      }
    })
  }
}

export default filterReducer;