/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { StyleSheet, View } from 'react-native';
import { RadioButton, ActivityIndicator, Subheading, Title } from 'react-native-paper';
import { setDelivery } from '../../reducers/orderReducer';
import theme from '../../theme/index';
import { getAll } from '../../services/deliveryService';

const DeliveryOptions = () => {
  const dispatch = useDispatch()
  const [deliveries, setDeliveries] = useState([])
  const [deliveryTypeValue, setDeliveryTypeValue] = useState(null)

  useEffect(() => {
    const getDeliveries = async () => {
      const data = await getAll()
      setDeliveries(data)
    }
    getDeliveries()
  }, [])

  if (!deliveries) {
    return <ActivityIndicator />
  }

  const deliveryTypes = [... new Set(deliveries.map(delivery => delivery.name))]

  const getDeliveryPrice = (name) => {
    return deliveries.find(delivery => delivery.name === name).priceInCents / 100
  }

  const onDeliveryTypeChange = (newValue) => {
    const index = deliveries.find(delivery => delivery.name === deliveryTypes[newValue])
    setDeliveryTypeValue(newValue)
    dispatch(setDelivery(deliveries[deliveries.indexOf(index)]))
  }

  return (
    <View>
      <Title style={styles.title}>Toimitustapa</Title>
      <RadioButton.Group onValueChange={newValue => onDeliveryTypeChange(newValue)} value={deliveryTypeValue}>
        {deliveryTypes.map((name, index) =>
          <DeliveryType key={index} name={name} value={index} price={getDeliveryPrice(name)} deliveries={deliveries} active={deliveryTypeValue === index} />
        )}
      </RadioButton.Group>
    </View>
  );
};

const DeliveryType = ({ name, value, deliveries, active, price }) => {
  const dispatch = useDispatch()
  const [deliveryOptionValue, setDeliveryOptionValue] = useState(0)
  const options = deliveries.filter(delivery => delivery.name === name)

  const onDeliveryOptionChange = (newValue) => {
    setDeliveryOptionValue(newValue)
    dispatch(setDelivery(options[newValue]))
  }

  return (
    <View>
      <View style={styles.deliveryType}>
        <RadioButton value={value} color={theme.colors.primary} />
        <Subheading style={styles.pickupType}>{name}</Subheading>
        <Subheading style={styles.pickupPrice}>{price.toFixed(2)}€</Subheading>
      </View>
      {active &&
        <RadioButton.Group onValueChange={newValue => onDeliveryOptionChange(newValue)} value={deliveryOptionValue}>
          {options.length >= 1 ? options.map((option, index) => <Option option={option} key={index} value={index} />) : null}
        </RadioButton.Group>
      }
    </View>
  )
}

const Option = ({ value, option }) => {
  return 'pickupTime' in option
    ? <View style={styles.pickupTimeContainer}>
      <RadioButton value={value} color={theme.colors.primary} />
      <Subheading style={styles.pickupTime}>{option.pickupTime !== 0 ? `${option.pickupTime} minuutin kuluttua` : 'Heti'}</Subheading>
    </View>
    : null
}

const styles = StyleSheet.create({
  deliveryType: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  pickupTimeContainer: {
    marginLeft: 40,
    flexDirection: 'row',
    alignItems: 'center'
  },
  pickupType: {
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary,
  },
  pickupPrice: {
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary,
    marginLeft: 'auto'
  },
  pickupTime: {
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary
  },
  title: {
    marginVertical: 20,
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary
  }
})

export default DeliveryOptions;