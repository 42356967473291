import { getOne } from "../services/restaurantService";
import theme from "../theme/index";
import { notify } from "./notificationReducer";

const restaurantReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_RESTAURANT_DETAILS":
      return action.data
    default:
      return state;
  }
};

export const setRestaurantDetails = () => {
  return async (dispatch) => {
    let restaurant;
    try {
      restaurant = await getOne();
    } catch {
      dispatch(notify({ message: 'Virhe noudettaessa ravintolan tietoja', color: theme.colors.error.main }))
    }

    dispatch({
      type: "SET_RESTAURANT_DETAILS",
      data: restaurant,
    });
  };
};

export default restaurantReducer;
