import React from 'react';
import Constants from 'expo-constants';
import { Image, TouchableOpacity, View, StyleSheet } from 'react-native';
import { Paragraph, Title } from 'react-native-paper';
import theme from '../../theme/index';

const PaymentOptionsList = ({ methods }) => {

  return (
    <View>
      {methods?.groups?.map(group => {
        return <PaymentOptionGroup key={group.id} providers={methods.providers} id={group.id} name={group.name} icon={group.icon} />
      })}
    </View>
  )
}

const PaymentOptionGroup = ({ providers, id, name, icon }) => {
  return (
    <View>
      <View style={styles.titleContainer}>
        <Image source={{ uri: icon }} style={styles.groupLogo} />
        <Title style={styles.groupTitle} >{name}</Title>
      </View>
      <Paragraph>{providers.map(provider => {
        return id === provider.group
          ? <PaymentOptionButton provider={provider} key={provider.name} />
          : null
      })}</Paragraph>
    </View>
  )
}

const PaymentOptionButton = ({ provider }) => {
  const api = Constants.manifest.extra.uri;

  const onPress = (provider) => {
    const { parameters } = provider;
    const query = parameters.reduce((acc, curr) => `${acc}${encodeURIComponent(curr.name)}=${encodeURIComponent(curr.value)}&`, '?');
    window.location.assign(`${api}/payments/payment_form${query}url=${encodeURIComponent(provider.url)}`)
  };

  return (
    <TouchableOpacity key={provider.name} onPress={() => onPress(provider)} style={styles.button}>
      <Image style={styles.tinyLogo} source={{ uri: provider.svg }} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    backgroundColor: theme.colors.mainBackground,
    width: 150,
    height: 60,
    padding: 5,
    margin: 5,
    borderColor: 'black',
    borderWidth: 1,
    borderRadius: 10
  },
  tinyLogo: {
    width: "100%",
    height: "100%"
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 15
  },
  groupTitle: {
    fontFamily: theme.fonts.primary,
    fontSize: 24
  },
  groupLogo: {
    height: 24,
    width: 24,
    marginRight: 10
  }
})

export default PaymentOptionsList;