import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import theme from '../../theme/index';
import { Toimitusehdot } from '../../assets/terms';

const TermsModal = ({ visible, setVisible }) => {

  const onDismiss = () => {
    setVisible(false)
  }

  return (
    <Portal>
      <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Toimitusehdot />
        </ScrollView>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    width: '70%',
    height: '70%',
    maxWidth: 500,
    maxHeight: 500,
    backgroundColor: theme.colors.mainBackground,
    borderRadius: 10,
  }
})

export default TermsModal;