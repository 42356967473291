import React, { useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';

import { useDispatch } from 'react-redux';
import { initializeShoppingCart } from '../reducers/orderReducer';
import { setRestaurantDetails } from '../reducers/restaurantReducer';
import RootStack from '../navigation/RootStack';

const config = {
  screens: {
    Ehdot: {
      path: 'terms'
    },
    Seuranta: {
      path: 'personal/orders/tracking',
    },
    NavBar: {
      screens: {
        RestaurantStack: {
          screens: {
            Bumma: '',
            PaymentErrorStack: {
              path: 'payments',
              screens: {
                Hylätty: 'failure',
                Peruutettu: 'cancel'
              }
            }
          }
        },
        CartStack: {
          path: 'cart',
          screens: {
            Esikatselu: 'preview',
            Tiedot: 'details'
          }
        }
      }
    }
  }
}

const linking = {
  config
};

const Main = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setRestaurantDetails())
    dispatch(initializeShoppingCart())
  })

  return (
    <NavigationContainer linking={linking}>
      <RootStack />
    </NavigationContainer>
  )
}

export default Main;