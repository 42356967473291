import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import CartPreview from '../screens/CartPreview/index';
import CartDetails from '../screens/CartDetails/index';
import CartPaymentOptions from '../screens/CartPaymentOptions/index';

const Stack = createStackNavigator();

const CartStack = () => (
  <Stack.Navigator
    initialRouteName='Preview'
    screenOptions={{
      headerShown: false
    }}>
    <Stack.Screen
      name='Esikatselu'
      component={CartPreview}
      options={{ title: 'Ostoskori --- BummaDeli' }}
    />
    <Stack.Screen
      name='Tiedot'
      component={CartDetails}
      options={{ title: 'Tiedot --- BummaDeli' }}
    />
    <Stack.Screen
      name='Maksutavat'
      component={CartPaymentOptions}
    />
  </Stack.Navigator>
)


export default CartStack;