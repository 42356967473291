/* eslint-disable no-undef */
import { Platform } from 'react-native';
import { DefaultTheme } from 'react-native-paper';
import * as Font from 'expo-font';

const loadFonts = async () => {
  await Font.loadAsync({
    Scheme: require('../assets/Fonts/Scheme.otf'),
    Chantal: require('../assets/Fonts/Chantal.ttf'),
    WorkSans: require('../assets/Fonts/WorkSans.ttf')
  })
}

loadFonts()

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    mainBackground: '#fefefe',
    navigation: '#fadfae',
    error: {
      light: '#ef5350',
      main: '#d32f2f',
      dark: '#c62828'
    },
    success: {
      light: '#4caf50',
      main: '#2e7d32',
      dark: '#1b5e20'
    },
    warning: {
      light: '#ff9800',
      main: '#ED6C02',
      dark: '#e65100'
    },
    info: {
      light: '#03a9f4',
      main: '#0288d1',
      dark: '#01579b'
    },
    primary: '#00667d',
    secondary: '#553c11'
  },
  fontSizes: {
    menu: {
      title: 17,
      description: 13
    },
    cart: {
      title: 20,
      ingredients: 12,
      additionalDetails: 12
    },
    categoryScroll: 12,
    subheading: 16,
    heading: 30,
  },
  fonts: {
    main: Platform.select({
      android: 'Roboto',
      ios: 'Arial',
      default: 'System',
    }),
    heading: 'Chantal',
    primary: 'Nunito',
    footer: 'WorkSans'
  },
  borderRadius: {
    normal: 4,
  },
  fontWeights: {
    normal: '400',
    bold: '700',
  },
};

export default theme;
