import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Paragraph, Subheading } from "react-native-paper";

export const Toimitusehdot = () => {
  return (

    <View>
      <Paragraph>{`Nämä toimitusehdot ovat voimassa 04.06.2021 alkaen.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Verkkokauppa\nBumma pizza & bar\nSibeliuksenkatu 19\n04400 Järvenpää`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`0505432591\nbummadeli@gmail.com\nwww.bumma.fi\nY-tunnus 3198532-4`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Kauppa myy tuotteita täysi-ikäisille yksityishenkilöille. Tuotteiden hinnat sisältävät arvonlisäveron. Pidätämme oikeuden hintojen ja toimituskulujen muutoksiin.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Tilaaminen`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Tuotteet tilataan verkkokaupassa siirtämällä ne ostoskoriin ja maksamalla ostoskorin sisältö verkkomaksupalvelussa. Kaikki asiakastiedot käsitellään luottamuksellisesti. Tilauksen yhteydessä kysyttyjä yhteystietoja ei käytetä muuhun kuin tilauksen toimitukseen tai siinä ilmenevien epäselvyyksien selvittämiseen, ellei erikseen toisin mainita. Tilatessasi verkkokaupasta sinun edellytetään tutustuneen ja sitoutuneen kulloinkin voimassa oleviin toimitusehtoihin.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Maksaminen ja maksutavat`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii Paytrail Oyj (2122839-7) yhteistyössä suomalaisten pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä tuotteen toimittajaan.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Paytrail Oyj, y-tunnus: 2122839-7\nInnova 2\nLutakonaukio 7\n40100 Jyväskylä\n`}<a href="http://www.paytrail.com">www.paytrail.com</a></Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Painamalla ”Jatka maksamaan” painiketta hyväksyt Bumman yleiset ehdot.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Tilaus- ja maksuvahvistus`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Kun olemme vastaanottaneet tilauksesi, lähetämme sinulle välittömästi sähköpostilla tilausvahvistuksen, josta näkyvät tilaustietosi. Tarkasta aina tilausvahvistuksen sisältö. Jos sinulla on kysyttävää, ota heti yhteys asiakaspalveluumme. Säästä tilausvahvistus, mikäli joudut ottamaan yhteyttä asiakaspalveluun. Asioidessasi asiakaspalvelussa pidä aina mahdollinen asiakasnumerosi ja tilausnumerosi saatavilla. Tarkasta aina, että paketin sisältö täsmää tilausvahvistuksessa olevien tuotteiden kanssa.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Asiakaspalveluumme saat yhteyden seuraavilla tiedoilla`}</Paragraph>
      <Paragraph style={styles.indent}>{`Bumma Pizza & Bar\nPuhelin: 0505432591`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Toimitustavat ja -kulut`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Toimituskulut sisältävät pakkauskulut. Tarkat toimituskulut näet valittuasi tilaukselle haluamasi maksu- ja toimitustavat. Käytössä olevat toimitustavat ovat itsenouto tai kotiinkuljetus. Voit valita kassalla käytössä olevista toimitustavoista sinulle parhaiten sopivan vaihtoehdon, jonka yhteydessä tarkka toimituskulu on esitetty.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Toimitusaika`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Yleisimmät toimitusaikamme vaihtelevat tilaustilanteesta ja toimitustavasta riippuen. Tilausvahvistuksessa annamme arvion toimitusajasta. Poikkeukset toimitusaika-arvioihin on kerrottu, jos ostoskorissa on varastosta loppuneita tuotteita ja ilmoitamme myös mahdollisista viivästyksistä teille välittömästi tilaamisen jälkeen.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Emme vastaa ylivoimaisen esteen aiheuttamista viivästymisistä tai viivästysten aiheuttamista välillisistä haitoista. Verkkokauppa tiedottaa sivuillaan poikkeavista toimitusajoista.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Palautusehdot`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Tilattua tuotetta ei voi palauttaa. Mikäli haluatte reklamoida tuotteesta, tai vaatia hyvitystä, pyydämme ottamaan yhteyttä asiakaspalveluumme puhelimitse tai sähköpostitse.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph style={styles.indent}>{`Puhelin: 0505432591\nSähköposti: bummadeli@gmail.com`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Mahdollisissa palautustapauksissa maksunpalautus tehdään tilauksessa käytetylle maksutavalle (ei esimerkiksi tilisiirtona).`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Lunastamaton paketti`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Lunastamatta jättäminen ei ole sama asia kuin peruutus. Lunastamatta jätetystä ruoka-annoksesta, josta ei ole tehty erillistä peruutusilmoitusta, perimme kustannukset täysimääräisinä, maksua ei palauteta.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Ruokatoimituksen yhteydessä asiakkaan tulee olla tilauksen yhteydessä ilmoitetussa toimitusosoitteessa. Mikäli toimituskuski ei saa toimitettua ruoka-annosta asiakkaalle toimitusosoitteessa poissaolon vuoksi emme hyvitä toimittamatta jäänyttä annosta. Toimituskuski soittaa kahdesti asiakkaan antamaan numeroon, mikäli asiakas ei ole toimitusosoitteessa tavattavissa. Kahden yhteydenottoyrityksen jälkeen toimituskuski poistuu paikalta.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Ongelmatilanteet`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Mikäli tuote on kadonnut tai vioittunut kuljetuksen aikana tai se ei muuten vastaa tilaustasi, tulee sinun ilmoittaa virheestä heti soittamalla yhteystiedoissa mainittuun puhelinnumeroon. Jos paketti on selvästi vaurioitunut kuljetuksessa, teidän täytyy tehdä reklamaatio yhteystiedoissa mainittuun puhelinnumeroon heti.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
    </View>
  )
}

export const Tietosuojaseloste = () => {
  return (
    <View>
      <Subheading style={styles.bold}>{`BummaDeli verkkokauppa`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Päivitetty 01.06.2021`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Me arvostamme jokaisen yksityisyyttä ja ymmärrämme tietojen keräämiseen sekä säilyttämiseen liittyvän vastuumme. Otamme tietosuoja-asiat erittäin vakavasti ja pidämme aina ensiluokkaista huolta kaikesta keräämästämme, saamastamme ja säilyttämästämme tiedosta. Meille on tärkeää, että voimme myös tarjota asiakkaillemme mahdollisuuden päästä tarkastelemaan omia tietojaan tai tulla kokonaan unohdetuksi. Bummadeli palveluita käyttääkseen asiakkaan on hyväksyttävä tässä tietosuojaselosteessa esitetyt ehdot.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Lyhyesti minkälaista tietoa kerätään ja säilytetään?`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Säilytämme ja keräämme seuraavia tietoja:`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph style={styles.indent}>{`Käyttäjän antamia tietoja\nBummaDeli verkkopalveluiden käytöstä tallentuvia tietoa`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Näitä kahta muotoa avataan tarkemmin kohdassa “mitä tietoja säilytämme ja keräämme?`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Rekisterinpitäjä`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`FPE Finnish Pizza Enterprice Oy\nSibeliuksenkatu 19\n04400 Järvenpää\nY-tunnus 3198532-4`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Rekisterin nimi`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`FPE Finnish Pizza Enterprice Oy:n asiakasrekisteri`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Mitä tietoja keräämme ja säilytämme?`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph style={styles.indent}>{`Nimi, puhelinnumero, sähköpostiosoite ja postiosoite`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`FPE Finnish Pizza Enterprice Oy:n verkkopalveluiden käytöstä tallentuvaa tietoa`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph style={styles.indent}>{`Toimitusosoite ja maksutapa\nOstoshistoria: tilatut tuotteet, palautukset ja maksut\nVerkkokaupan selaus- ja käyttötiedot\nKäytetyn laitteen tunnistetiedot\nVerkkokauppaistuntojen tapahtumat, esimerkiksi ostoskorilisäykset`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Mihin tietojani käytetään?`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Henkilötietoja käytetään:`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph style={styles.indent}>{`Tilausten käsittely: käsittely, vastaanottaminen, toimitus, arkistointi ja kirjanpito`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Kuka voi käsitellä tietojani ja missä tietoja säilytetään?`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Noudatamme asiakastietojen käsittelyssä hyviä tietosuojakäytäntöjä ja pääsyä tietoihin valvotaan. 25.5.2018 alkaen EU:n tietosuoja-asetuksen vaatimukset on otettu toiminnassamme huomioon ja näitä vaatimuksia noudatetaan toiminnassa. Asiakasrekisterin tiedot on tallennettu järjestelmään, johon sisäänpääsy edellyttää käyttäjätunnuksen ja salasanan syöttämistä. Järjestelmä on myös suojattu palomuurein ja muiden teknisten keinojen avulla. Järjestelmään tallennettuihin rekisterin sisältämiin tietoihin pääsevät ja niitä ovat oikeutettuja käyttämään vain tietyt, ennalta määritellyt ja koulutetut FPE Finnish Pizza Enterprice Oy:n työntekijät. Rekisterin sisältämät tiedot sijaitsevat lukituissa ja vartioiduissa tiloissa. Joissakin tapauksissa meillä on tarve siirtää tietoa kolmansille osapuolille, esim. säilytystä varten. Kaikkien yhteistyökumppaneidemme kanssa on olemassa tiukat sopimukset ja sopimuksissa on otettu huomioon lainsäädännön sekä EU:n tietosuoja-asetuksen vaatimukset. Yhteistyökumppaneillamme ei missään tapauksessa ole lupaa käyttää tietoja omiin tarkoituksiinsa tai jakaa tietoja eteenpäin.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Kuinka kauan säilytämme tietoja?`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Säilytämme tietoja tarvittavan ajan, jotta pystymme toimimaan tämän selosteen mukaisesti. Henkilötiedot asiakastileiltä, jotka eivät ole aktiivisia poistetaan viimeistään viiden vuoden kuluttua viimeistä toiminnasta. Joitain tietoja joudumme esimerkiksi kirjanpitovelvollisuuden takia säilyttämään pidempään, mikäli laki meitä siihen edellyttää. Asiakkaan on mahdollista anonymisoida asiakastilinsä mikä tarkoittaa, että kaikki henkilötiedot poistetaan tililtä ja ostohistoriasta. Tällöin asiakasta ei enää voida tunnistaa eikä tiliä pysty enää palauttamaan. Tämän toimenpiteen voi toteuttaa lähettämällä viestin osoitteeseen: bummadeli@gmail.com`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Miten voin nähdä tai poistaa omat tietoni?`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Asiakkaalla on aina oikeus nähdä omat tietonsa ja pyytää tietojensa poistamista. Oikaisupyynnöt, poistopyynnöt tai muut asiakastietoihisi liittyvät pyynnöt tai kysymykset voit lähettää meille osoitteeseen bummadeli@gmail.com`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Tiedon korjaaminen`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Rekisterinpitäjä oikaisee, poistaa tai täydentää rekisterissä olevan, käsittelyn tarkoituksen kannalta virheellisen, tarpeettoman, puutteellisen tai vanhentuneen henkilötiedon oma-aloitteisesti tai asiakkaan vaatimuksesta. Rekisteröidyn tulee ottaa yhteyttä rekisterinpitäjän rekisteriasioista vastaavaan henkilöön tiedon korjaamiseksi.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Subheading style={styles.bold}>{`Päätyykö tietojani kolmansille osapuolille?`}</Subheading>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Vain pakollisissa tapauksissa, eli ainoastaan maksutapahtumien mahdollistamiseksi. Verkkokaupan maksutapahtumat tarjoaa maksunvälittäjä Paytrail ja maksutapahtuman yhteydessä maksutiedot kulkevat Paytrailin kautta. Nämä kolmannet osapuolet eivät sopimustemme mukaan missään tapauksessa saa käyttää mitään tietoja omiin tarkoituksiinsa ja heille toimitetaan vain palvelun toteutumisen kannalta oleellinen tieto. Kaikki tiedonsiirto tapahtuu salatun yhteyden kautta.`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Tätä tietosuojaselostetta päivitetään tarvittaessa`}</Paragraph>
      <Paragraph>{``}</Paragraph>
      <Paragraph>{`Maailma muuttuu kovaa vauhtia ja uusia säädöksiä sekä toiminnallisia mahdollisuuksia ilmestyy tiuhaan tahtiin. Me FPE Finnish Pizza Enterprice Oy:ssä seuraamme aktiivisesti muutoksia ja päivitämme tietosuojaselostetta aina tarvittaessa. Viimeisimmän päivityksen päivämäärän löydät aina tietosuojaselosteen ylälaidasta. Kiitos tietosuojaselosteen lukemisesta. Vastaamme mielellämme kysymyksiin, mikäli jokin seikka tietojen säilyttämisessä, käsittelyssä tai käytössä jäi epäselväksi. Kysymyksiä voi lähettää osoitteeseen bummadeli@gmail.com`}</Paragraph>
      <Paragraph>{``}</Paragraph>
    </View>
  )
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: 700
  },
  indent: {
    marginLeft: '2%'
  }
})