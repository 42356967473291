import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Paragraph, Title, ProgressBar } from 'react-native-paper';
import theme from '../../theme/index';

import { MaterialCommunityIcons, Entypo, Octicons, EvilIcons } from '@expo/vector-icons';

const TrackingCard = ({ status }) => {

  const checkStatus = () => {
    switch (status) {
      case 'paid':
        return {
          title: 'Tilausta käsitellään...',
          description: 'Maksu vastaanotettu ja tilaus odottaa ravintolan kuittausta',
          progress: 0.25,
          icon: <Entypo name="dots-three-horizontal" size={50} color={theme.colors.primary} />
        };
      case 'received':
        return {
          title: 'Tilaus vastaanotettu',
          description: 'Ravintola on vastaanottanut tilauksesi ja se valmistetaan tuota pikaa!',
          progress: 0.5,
          icon: <MaterialCommunityIcons name="progress-check" size={100} color={theme.colors.primary} />
        };
      case 'done':
        // TODO -> tsekkaa onko nouto vai kuljetus ja kustomoi viesti sen mukaan
        return {
          title: 'Tilaus valmis',
          description: 'Tilauksesi on valmis noudettavaksi/kuljetettavaksi',
          progress: 0.75,
          icon: <Octicons name="check" size={100} color={theme.colors.primary} />
        };
      case 'delivered':
        return {
          title: 'Tilaus toimitettu',
          description: 'Kiitos tilauksestasi ja hyvää ruokahalua!',
          progress: 1,
          icon: <EvilIcons name="heart" size={100} color={theme.colors.primary} />
        };
      default:
        return {
          title: 'Tilausta ei löydy',
          description: 'Jos tilausvahvistuksessa saamasi linkki on virheellinen, ota yhteyttä asiakaspalveluun'
        }
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        {checkStatus().icon}
      </View>
      <Title style={styles.title}>{checkStatus().title}</Title>
      <Paragraph style={styles.description}>{checkStatus().description}</Paragraph>
      <View style={styles.progressBarContainer}>
        <ProgressBar progress={checkStatus().progress} color={theme.colors.primary} style={{ height: 5 }} visible={status !== undefined} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10
  },
  iconContainer: {
    margin: 50
  },
  title: {
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary,
    fontSize: 24
  },
  description: {
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary,
    textAlign: 'center'
  },
  progressBarContainer: {
    height: 5,
    width: '100%',
    maxWidth: 500,
    marginTop: 40
  }
})

export default TrackingCard;