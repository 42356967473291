import React from 'react';
import { View, StyleSheet } from 'react-native';
import { List, Title, Divider, Subheading } from 'react-native-paper';
import theme from '../../../theme/index';
import Product from './Product';

const ProductListSection = ({ productList, category, isFirst }) => {

  if (!productList) return null;

  return (
    <List.Section>
      {!isFirst &&
        <Divider />
      }
      <View>
        <Title style={styles(category.available).title}>{category.name}</Title>
        <Subheading style={styles(category.available).description}>{category.description}</Subheading>
      </View>
      <View style={styles().listContainer}>
        {productList.map((product, index) =>
          product.category.id === category.id
            ? <Product product={product} key={product.id} index={index} categoryAvailable={category.available} />
            : null
        )}
      </View>
    </List.Section>
  )
}

const styles = (available) => StyleSheet.create({
  title: {
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 10,
    fontSize: theme.fontSizes.heading,
    color: theme.colors.primary,
    fontFamily: theme.fonts.primary,
    letterSpacing: 1,
    opacity: available ? 1 : 0.3
  },
  description: {
    fontSize: theme.fontSizes.subheading,
    color: theme.colors.primary,
    fontFamily: theme.fonts.primary,
    textAlign: 'center',
    paddingBottom: 20,
    paddingHorizontal: 10,
    opacity: available ? 1 : 0.3
  },
  listContainer: {
    minHeight: 200
  }
})

export default ProductListSection;