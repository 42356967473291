import React from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet } from 'react-native';
import { Chip } from 'react-native-paper';
import theme from '../../../theme';

const OpenClosedChip = () => {
  const open = useSelector(({ restaurant }) => restaurant?.open) || false;

  const styles = StyleSheet.create({
    root: {
      backgroundColor: open
        ? theme.colors.success.light
        : theme.colors.error.light
    },
    text: {
      color: 'rgba(255, 255, 255, 0.9)',
      fontWeight: theme.fontWeights.bold,
    }
  })

  return (
    <Chip
      mode="flat"
      style={styles.root}
      textStyle={styles.text}
    >
      {open ? 'Avoinna' : 'Suljettu'}
    </Chip>
  )
}

export default OpenClosedChip;