import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import { StyleSheet, View } from 'react-native';
import { Button, Checkbox, Paragraph } from 'react-native-paper';
import FormikTextInput from '../../components/Formik/FormikTextInput';
import { submitOrder } from '../../reducers/orderReducer';
import theme from '../../theme/index';

const DetailsForm = ({ setVisible, checked, setChecked, navigation }) => {
  const dispatch = useDispatch()
  const open = useSelector(({ restaurant }) => restaurant?.open);
  const currentOrder = useSelector(state => state.currentOrder)
  const deliveryIndex = useSelector(state => state.currentOrder.delivery)

  const openPaymentPage = (values) => {
    navigation.navigate('Maksutavat', values)
  }

  const proceedToPayment = (values) => {
    dispatch(submitOrder(currentOrder, deliveryIndex, values, openPaymentPage))
  }

  const hasErrors = (errors) => {
    return JSON.stringify(errors) !== JSON.stringify({})
  }

  const isDisabled = (errors) => {
    if (open === false || currentOrder.cartProducts.length === 0 || hasErrors(errors) || checked === false) {
      return true
    }
    return false
  }

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    postalCode: '',
    additionalInfo: '',
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('Etu- ja sukunimi vaaditaan'),
    email: yup
      .string()
      .required('Sähköpostiosoite vaaditaan')
      .email('Virheellinen sähköpostiosoite'),
    phone: yup
      .string()
      .required('Puhelinnumero vaaditaan'),
    address: yup
      .string()
      .required('Toimitusosoite vaaditaan'),
    city: yup
      .string()
      .required('Paikkakunta vaaditaan'),
    postalCode: yup
      .string()
      .required('Postinumero vaaditaan')
      .min(5, 'Virheellinen postinumero')
      .max(5, 'Virheellinen postinumero')
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => proceedToPayment(values)}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, errors }) => (
        <View>
          <FormikTextInput name='name' label='Nimi' />
          <FormikTextInput name='email' label='Sähköpostiosoite' />
          <FormikTextInput name='phone' label='Puhelinnumero' />
          <FormikTextInput name='address' label='Toimitusosoite' />
          <FormikTextInput name='city' label='Paikkakunta' />
          <FormikTextInput name='postalCode' label='Postinumero' />
          <FormikTextInput name='additionalInfo' label='Lisätietoja kuljettajalle' placeholder="esim. ovikoodi" />
          <View style={styles.terms}>
            <Paragraph>Olen lukenut ja hyväksyn BummaDelin yleiset <Paragraph style={styles.link} onPress={() => setVisible(true)}>ehdot</Paragraph></Paragraph>
            <Checkbox
              status={checked ? 'checked' : 'unchecked'}
              onPress={() => {
                setChecked(!checked);
              }}
            />
          </View>
          <Button
            style={styles.submitButton}
            mode='contained'
            onPress={handleSubmit}
            disabled={isDisabled(errors)}>
            {hasErrors(errors) ? 'Korjaa tiedot' : 'Jatka maksamaan'}
          </Button>
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  submitButton: {
    margin: 10
  },
  terms: {
    maxWidth: 250,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.colors.primary
  }
})

export default DetailsForm;