/* eslint-disable react/display-name */
import React from 'react';
import { Dimensions, StyleSheet, useWindowDimensions } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { FontAwesome5 } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';


import CartStack from './CartStack';
import RestaurantStack from './RestaurantStack';
import theme from '../theme/index';
import NavTab, { IconWithBadge } from '../components/NavTab';


const Tabs = createMaterialTopTabNavigator();

const NavBar = () => {
  const width = useWindowDimensions().width

  return (
    <Tabs.Navigator
      name='NavBar'
      swipeEnabled={false}
      initialLayout={{ width: Dimensions.get('window').width }}
      tabBarOptions={{
        style: styles.tab,
        labelStyle: styles.label,
        activeTintColor: theme.colors.primary,
        indicatorStyle: styles.indicator,
        showIcon: width > 1100 ? false : true,
      }}
      tabBarPosition={width > 1100 ? 'top' : 'bottom'}
    >
      <Tabs.Screen
        name='RestaurantStack'
        component={RestaurantStack}
        options={
          {
            tabBarLabel: width > 1100 ? (props) => <NavTab {...props} title='Pizza menu' icon={<FontAwesome5 {...props} name="pizza-slice" size={28} />} /> : '',
            tabBarIcon: (props) => <FontAwesome5 {...props} name="pizza-slice" size={28} /> 
          }
        } />
      <Tabs.Screen
        name='CartStack'
        component={CartStack}
        options={
          {
            tabBarLabel: width > 1100 ? (props) => <NavTab {...props} showBadge title='Ostoskori' icon={<Ionicons {...props} name='md-cart-outline' size={32} />} /> : '',
            tabBarIcon: (props) => <IconWithBadge right={-10} icon={<Ionicons {...props} name='md-cart-outline' size={32} />} />
          }}
      />
    </Tabs.Navigator>
  )
}

const styles = StyleSheet.create({
  tab: {
    backgroundColor: theme.colors.navigation,
  },
  label: {
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.subheading,
    textTransform: 'lowercase'
  },
  indicator: {
    backgroundColor: theme.colors.primary
  }
})

export default NavBar;