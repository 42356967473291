/* eslint-disable no-undef */
import React from 'react'

import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import theme from '../../theme/index'

const Header = () => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => window.open('https://bummadeli.fi')}
        style={styles.logoContainer}
        activeOpacity={1}
      >
        <Image
          source={require('../../assets/bummaLogoPetrol.png')}
          style={styles.logoContainer}
        />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 150,
    backgroundColor: theme.colors.navigation,
    justifyContent: 'center'
  },
  logoContainer: {
    height: 130,
    width: 130,
    alignSelf: 'center'
  }
})

export default Header;