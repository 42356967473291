import React from 'react';
import { useSelector } from 'react-redux';
import { ActivityIndicator } from 'react-native-paper';
import ScreenContainer from '../../layouts/ScreenContainer/index'
import CategoryScroll from './CategoryScroll';
import { ScrollView, StyleSheet, View } from 'react-native';
import ProductList from './Products/ProductList';
import theme from '../../theme/index';

const Restaurant = () => {
  const restaurant = useSelector(({ restaurant }) => restaurant);

  if (!restaurant) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator /></View>
    )
  }

  return (
    <ScreenContainer>
      <View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.container}
        >
          <CategoryScroll />
          <ProductList />
        </ScrollView>
      </View>
    </ScreenContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.mainBackground
  }
})

export default Restaurant;
