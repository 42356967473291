import React from "react";
import { Provider as PaperProvider } from "react-native-paper";
import { Provider } from "react-redux";
import { useFonts, Nunito_800ExtraBold } from "@expo-google-fonts/nunito";

import Main from "./src/components/Main";
import store from './src/store';
import theme from "./src/theme/index";
import { ActivityIndicator } from "react-native";

const App = () => {
  let [fontsLoaded] = useFonts({
    Nunito: Nunito_800ExtraBold,
  });

  if (!fontsLoaded) return <ActivityIndicator />

  return (
    <Provider store={store}>
      <PaperProvider theme={theme}>
        <Main />
      </PaperProvider>
    </Provider>
  );
};

export default App;
