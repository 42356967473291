const notificationReducer = (state = null, action) => {
  switch (action.type) {
    case "NOTIFY":
      return action.data;
    case "CLEAR_NOTIFICATION":
      return null;
    default:
      return state;
  }
};

export const notify = ({ message, color }) => async (dispatch) => {
  dispatch({
    type: "CLEAR_NOTIFICATION",
  });
  dispatch({
    data: { message, color },
    type: "NOTIFY",
  });
};

export const clear = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_NOTIFICATION",
  });
};

export default notificationReducer;
