import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import Restaurant from '../screens/Restaurant/index';
import PaymentErrorStack from './PaymentErrorStack';

const Stack = createStackNavigator();

const RestaurantStack = () => (
  <Stack.Navigator
    screenOptions={{
      headerShown: false
    }}>
    <Stack.Screen
      name='Bumma'
      component={Restaurant}
      options={{ title: 'Menu --- BummaDeli' }}
    />
    <Stack.Screen
      name='PaymentErrorStack'
      component={PaymentErrorStack}
    />
  </Stack.Navigator>
)


export default RestaurantStack;