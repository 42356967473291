import React from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Button, Modal, Portal, TextInput, Title, IconButton, Paragraph } from 'react-native-paper';
import { useSelector } from 'react-redux';
import theme from '../../../../theme/index';
import ChoiceGroup from './ChoiceGroup';

const IngredientPickerModal = ({ visible, onDismiss, addToCart, cartProduct, additionalDetails, setAdditionalDetails, ...props }) => {
  const open = useSelector(({ restaurant }) => restaurant?.open);

  return (
    <Portal>
      <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.modalContainer}>
        <View style={styles.header}>
          <IconButton icon={'arrow-left'} color={theme.colors.primary} onPress={onDismiss} />
          <Title style={styles.title}>Lisävalinnat</Title>
        </View>
        <View style={styles.pickerContainer}>
          <IngredientsPicker
            choiceGroups={cartProduct.product.choiceGroups}
            {...props}
          />
        </View>
        <View>
          <TextInput
            mode='outlined'
            value={additionalDetails}
            onChangeText={(value) => setAdditionalDetails(value)}
            label='Lisätietoja'
            multiline={true}
            maxLength={200}
            style={{ marginTop: 10 }}

          />
        </View>
        <Paragraph  numberOfLines={2} style={styles.paragraph}>Selvitä muihin kuin lisätäytteisiin liittyvät toiveet etukäteen ravintolalta</Paragraph>
        <View>
          <Button
            disabled={open && cartProduct.product.availabilityBoolean ? false : true}
            mode='contained'
            onPress={() => addToCart(cartProduct)}>
            Lisää tilaukseen
          </Button>
        </View>
      </Modal>
    </Portal>
  );
};

export const IngredientsPicker = ({ choiceGroups, ...props }) => {

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
      >
        {choiceGroups.map((group, index) => <ChoiceGroup
          key={group.name}
          index={index}
          name={group.name}
          choices={group.choices}
          maxChoices={group.maxChoices}
          {...props}
        />)}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    width: '90%',
    height: '70%',
    maxWidth: 500,
    maxHeight: 500,
    backgroundColor: theme.colors.mainBackground,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 15
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 5
  },
  title: {
    fontFamily: theme.fonts.primary,
    color: theme.colors.primary,
    fontSize: 24
  },
  pickerContainer: {
    flex: 1,
    justifyContent: 'flex-start'
  },
  paragraph: {
    fontSize: 9,
    alignSelf: 'center'
  },
})

export default IngredientPickerModal;