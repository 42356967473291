import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Checkbox, Paragraph } from 'react-native-paper';
import theme from '../../../../theme/index';

const Ingredient = ({
  ingredient,
  addedIngredients,
  setAddedIngredients,
  addedIngredientsSubtotal,
  setAddedIngredientsSubtotal,
  cartProductSubtotal,
  setCartProductSubtotal,
  currentChoices,
  setCurrentChoices,
  maxChoices
}) => {
  const [checked, setChecked] = useState(false);

  const addIngredient = () => {
    if (!addedIngredients.includes(ingredient)) {
      setCurrentChoices(currentChoices.concat(ingredient))
      setAddedIngredients(addedIngredients.concat(ingredient))
      setCartProductSubtotal(cartProductSubtotal + ingredient.priceInCents)
      setAddedIngredientsSubtotal(addedIngredientsSubtotal + ingredient.priceInCents)
    }
  }

  const removeIngredient = () => {
    setAddedIngredients(addedIngredients.filter(addedIngredient => JSON.stringify(addedIngredient) !== JSON.stringify(ingredient)))
    setCurrentChoices(currentChoices.filter(choice => JSON.stringify(choice) !== JSON.stringify(ingredient)))
    setAddedIngredientsSubtotal(addedIngredientsSubtotal - ingredient.priceInCents)
    setCartProductSubtotal(cartProductSubtotal - ingredient.priceInCents)
  }

  const check = () => {
    if (currentChoices.length + 1 > maxChoices) {
      return
    }
    addIngredient()
    setChecked(true)
  }

  const uncheck = () => {
    setChecked(false)
    removeIngredient()
  }

  return (
    <View style={styles(ingredient.available).container}>
      <View style={styles().left}>
        <Paragraph style={styles().title}>{ingredient.name}</Paragraph>
        <Paragraph style={{ color: theme.colors.primary }}>+{(ingredient.priceInCents / 100).toFixed(2)}€</Paragraph>
      </View>
      <View>
        <Checkbox
          status={checked ? 'checked' : 'unchecked'}
          onPress={() => checked ? uncheck() : check()}
          color={theme.colors.primary}
          uncheckedColor={theme.colors.primary}
          disabled={!ingredient.available}
        />
      </View>
    </View>
  )
}

const styles = (available) => StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: available ? 1 : 0.3
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    color: theme.colors.primary,
    fontFamily: theme.fonts.primary,
    paddingRight: 10,
    fontWeight: theme.fontWeights.bold,
    letterSpacing: 1
  }
})

export default Ingredient;