import React from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { Button } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import TrackingCard from './TrackingCard';
import theme from '../../theme/index';

const Content = ({ order }) => {

  return (
    <View style={styles.container}>
      <View style={styles.cardContainer}>
        <TrackingCard status={order?.status} />
      </View>
      <View>
        <Button
          mode='contained'
          style={styles.button}
          labelStyle={styles.buttonLabel}
          icon={() => <FontAwesome5 name="pizza-slice" size={20} color={theme.colors.mainBackground} />}
          onPress={() => window.open('https://bummadeli.fi')}
        >
          Bummadeli
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: theme.colors.mainBackground,
    padding: 10
  },
  cardContainer: {
    width: '100%',
    maxWidth: 700
  },
  button: {
    borderRadius: 20,
    margin: 20
  },
  buttonLabel: {
    fontFamily: theme.fonts.heading,
    fontSize: 18
  }
})

export default Content;