import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import PaymentCancel from '../screens/PaymentCancel/index';
import PaymentFailure from '../screens/PaymentFailure';

const Stack = createStackNavigator();

const PaymentErrorStack = () => (
  <Stack.Navigator
    screenOptions={{
      headerShown: false
    }}>
    <Stack.Screen
      name='Peruutettu'
      component={PaymentCancel}
    />
    <Stack.Screen
      name='Hylätty'
      component={PaymentFailure}
    />
  </Stack.Navigator>
)


export default PaymentErrorStack;