import React, { useEffect, useState } from 'react';
import { getOrder } from '../../services/orderService';
import { StyleSheet, View } from 'react-native';
import Content from './Content';
import Footer from '../../components/Footer';
import Header from './Header';

const OrderTracking = ({ route }) => {
  const [order, setOrder] = useState(null);
  const orderId = route.params?.orderId
  const key = route.params?.key

  useEffect(() => {
    getOrder(orderId, key)
      .then((val) => {
        setOrder(val)
      })
      .catch((err) => console.log(err))
    localStorage.clear()
    poll()
  }, [])

  const poll = () => {
    setTimeout(() => {
      getOrder(orderId, key)
        .then((val) => {
          setOrder(val)
          if (val.status === 'delivered') return;
          poll();
        })
        .catch((err) => console.log(err))
    }, 100000);
  };

  return (
    <View style={styles.container}>
      <Header />
      <Content order={order} />
      <Footer />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    width: '100%'
  }
})

export default OrderTracking;
