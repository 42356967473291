import React from 'react';
import { View, StyleSheet } from 'react-native'
import { Button } from 'react-native-paper';
import { useLinkTo } from '@react-navigation/native';
import { useSelector } from 'react-redux';

export const CartPreviewFooter = () => {
  const linkTo = useLinkTo();
  const open = useSelector(({ restaurant }) => restaurant?.open);
  const currentOrder = useSelector(state => state.currentOrder)

  const isDisabled = () => {
    if (!open || currentOrder.cartProducts.length === 0 || currentOrder.delivery.name === null) {
      return true
    }
    return false
  }

  const getButtonText = () => {
    if (!open) return 'Suljettu'
    if (currentOrder.cartProducts.length === 0) return 'Ostoskori tyhjä'
    if (!currentOrder.delivery.name) return 'Valitse toimitustapa'
    return 'Jatka yhteystietoihin'
  }


  return (
    <View style={styles.container}>
      <Button
        mode='contained'
        disabled={isDisabled()}
        onPress={() => linkTo('/cart/details')}
        uppercase={false}
      >
        {getButtonText()}
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginTop: 45,
  },
})