import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Title } from 'react-native-paper';
import ScreenContainer from '../../layouts/ScreenContainer/index';
import { CartPreviewFooter as Footer } from './CartPreviewFooter';
import { ProductList } from './CartPreviewProductList/ProductList';
import theme from '../../theme/index';
import DeliveryOptions from './DeliveryOptions';


const CartPreview = () => {

  const innerContainer = {
    width: '90%',
    maxWidth: 750,
    marginVertical: 100,
  }

  return (
    <ScreenContainer>
      <View style={styles.container}>
        <View style={innerContainer}>
          <Title style={styles.title}>Tilauksesi</Title>
          <ProductList />
          <DeliveryOptions />
          <Footer />
        </View>
      </View>
    </ScreenContainer>
  )
}
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: theme.colors.mainBackground
  },
  title: {
    textAlign: 'center',
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.heading,
    fontWeight: theme.fontWeights.bold,
    color: theme.colors.primary
  }
})

export default CartPreview;