import React, { useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { IconButton, Button, List } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import ProductInfoModal from './ProductInfoModal';
import IngredientPickerModal from './IngredientPicker/IngredientPickerModal';
import { addProductToOrder, increaseSubtotal } from '../../../reducers/orderReducer';
import { notify } from '../../../reducers/notificationReducer';
import theme from '../../../theme/index';

const Product = ({ product, categoryAvailable }) => {
  const dispatch = useDispatch()
  const [infoVisibility, setInfoVisibility] = useState(false);
  const [pickerVisibility, setPickerVisibility] = useState(false);
  const [addedIngredients, setAddedIngredients] = useState([])
  const [addedIngredientsSubtotal, setAddedIngredientsSubtotal] = useState(0)
  const [additionalDetails, setAdditionalDetails] = useState('')
  const [cartProductSubtotal, setCartProductSubtotal] = useState(product.priceInCents)

  const cartProduct = {
    product,
    addedIngredients,
    additionalDetails,
    subtotal: cartProductSubtotal
  }

  const onInfoDismiss = () => {
    setInfoVisibility(false);
  }

  const onPickerDismiss = () => {
    setAddedIngredients([])
    setAddedIngredientsSubtotal(0)
    setAdditionalDetails('')
    setCartProductSubtotal(product.priceInCents)
    setPickerVisibility(false);
  }

  const addToCart = (obj) => {
    dispatch(addProductToOrder(obj, 1))
    dispatch(increaseSubtotal(addedIngredientsSubtotal))
    dispatch(notify({ message: 'Tuote lisätty ostoskoriin', color: theme.colors.success.light }))
    setAddedIngredientsSubtotal(0)
    setAddedIngredients([])
    setAdditionalDetails('')
    setCartProductSubtotal(product.priceInCents)
    setPickerVisibility(false)
  }

  const onPress = () => {
    setPickerVisibility(true)
  }

  const ListItem = ({ component }) => {
    if (!product.availabilityBoolean || !categoryAvailable) {
      return (
        <View style={styles.soldOut}>
          {component}
        </View>
      )
    }

    return component
  }

  return (
    <>
      <ProductInfoModal
        product={product}
        visible={infoVisibility}
        onDismiss={onInfoDismiss}
      />
      <IngredientPickerModal
        cartProduct={cartProduct}
        addedIngredients={addedIngredients}
        setAddedIngredients={setAddedIngredients}
        addedIngredientsSubtotal={addedIngredientsSubtotal}
        setAddedIngredientsSubtotal={setAddedIngredientsSubtotal}
        additionalDetails={additionalDetails}
        setAdditionalDetails={setAdditionalDetails}
        cartProductSubtotal={cartProductSubtotal}
        setCartProductSubtotal={setCartProductSubtotal}
        addToCart={addToCart}
        visible={pickerVisibility}
        onDismiss={onPickerDismiss}
      />
      <ListItem
        component={<List.Item
          style={styles.listItem}
          title={product.name}
          titleStyle={styles.titleStyle}
          titleNumberOfLines={2}
          right={() => <OnRight cartProduct={cartProduct} setInfoVisibility={setInfoVisibility} onPress={onPress} addToCart={addToCart} categoryAvailable={categoryAvailable} />}
          description={product.description}
          descriptionNumberOfLines={10}
          descriptionStyle={styles.descriptionStyle}
        />} />
    </>
  )
}

const OnRight = ({ cartProduct, setInfoVisibility, onPress, addToCart, categoryAvailable }) => {
  const open = useSelector(({ restaurant }) => restaurant?.open);

  return (
    <View style={styles.buttons}>
      <View style={styles.innerButtons}>
        <IconButton
          icon='help'
          size={25}
          color='white'
          style={styles.editButton}
          disabled={open && cartProduct.product.availabilityBoolean && categoryAvailable ? false : true}
          onPress={() => setInfoVisibility(true)}
        />
        <IconButton
          icon='pencil'
          size={25}
          style={styles.editButton}
          disabled={open && cartProduct.product.availabilityBoolean && categoryAvailable ? false : true}
          color='white'
          onPress={() => onPress()}
        />
      </View>
      <Button
        onPress={() => addToCart(cartProduct)}
        mode='contained'
        disabled={open && cartProduct.product.availabilityBoolean && categoryAvailable ? false : true}
        uppercase={false}
        style={styles.addToCartButton}
        dark
      >
        Lisää
      </Button>
      <Text style={styles.price}>
        {(cartProduct.product.priceInCents / 100).toFixed(2)} €
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    width: '30%',
    height: '50%',
    maxWidth: 100,
  },
  price: {
    fontSize: theme.fontSizes.subheading,
    fontWeight: theme.fontWeights.bold,
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
    borderWidth: 2,
    borderRadius: 4,
    paddingVertical: 5,
    marginTop: 5,
    width: '100%',
    textAlign: 'center',
  },
  editButton: {
    borderRadius: 4,
    backgroundColor: theme.colors.primary,
    color: theme.colors.mainBackground
  },
  addToCartButton: {
    width: '100%',
  },
  innerButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  listItem: {
    paddingHorizontal: '5%',
    minHeight: 150,
    minWidth: '90%',
    cursor: 'default',
  },
  titleStyle: {
    color: theme.colors.primary,
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.menu.title,
    fontWeight: theme.fontWeights.bold,
    textTransform: 'uppercase',
  },
  descriptionStyle: {
    maxWidth: '70%',
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.menu.description,
    color: theme.colors.primary,
  },
  soldOut: {
    opacity: 0.3
  }
})

export default Product;
