/* eslint-disable no-undef */
import React from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Footer from '../../components/Footer';
import Notification from '../../components/Notification';
import RestaurantHeader from './RestaurantHeader/index';
import theme from '../../theme/index';


const ScreenContainer = ({ children }) => {

  return (
    <View style={styles.container}>
      <ScrollView alwaysBounceVertical>
        <RestaurantHeader />
        <View style={styles.innerContainer}>
          {children}
        </View>
        <Notification />
        <ConfirmationDialog />
        <View style={styles.footerContainer}>
          <Footer />
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.primary
  },
  innerContainer: {
    maxWidth: 1100,
    width: '100%',
    alignSelf: 'center',
    marginTop: -100,
  },
  footerContainer: {
    flexGrow: 1,
    maxWidth: 1100,
    alignSelf: 'center',
    padding: 20
  }
})

export default ScreenContainer;

